const lowerCaseWords = ['a', 'an', 'the', 'and', 'of', ];

/**
 * Formats a selection to a URL (all lowercase,dashes instead of spaces).
 * @param {string} inputString - The URL parameter string, ex: 'united-states'
 * @return {string} The formatted parameter string, ex: 'United States'
 */
export default function urlToSelection(inputString) {
  return inputString
    .split('-')
    .map((word, i) => {
      if (i === 0 || !lowerCaseWords.includes(word)) {
        return word[0].toUpperCase() + word.substr(1);
      }
      return word;
    })
    .join(' ');
}