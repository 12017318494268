import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {
  dataPointHFLegendOWID,
  formatValue,
  isPercentageField,
} from '../../../utils';
import theme from '../../../theme';
const {
  cardPadding,
  fontSize,
} = theme;

const FootNote = ({ children }) => (
  <div style={{
    fontWeight: 'bold',
    lineHeight: '1.2',
    opacity: '0.5',
    textAlign: 'right',
  }}>
    {children}
  </div>
);

const Popup = ({
  field,
  properties,
}) => {
  const {
    location,
    [field]: value,
    [`${field}_updated`]: updated,
  } = properties;
  const displayValue = formatValue(value, isPercentageField(field));
  const perThousand = field.includes('_per_thousand');

  return (
    <>
      <h2 style={{ lineHeight: '1', margin: 0,  opacity: '0.5', }}>{dataPointHFLegendOWID[field.replace('_per_thousand', '')]}{perThousand && '*'}</h2>
      <h1 style={{ margin: '.75em 0', padding: `0 ${cardPadding}` }}>
        {`${location === 'United States of America' ? 'United States' : location}: `}
        <span style={{ color: 'red' }}>
          {displayValue}
        </span>
      </h1>
      {
        perThousand && (
          <FootNote>
            *Per 1,000 Population
          </FootNote>
        )
      }
      <FootNote>
        Updated {moment(updated).format('M/D/YYYY')}
      </FootNote>
    </>
  );
};

const StyledPopup =  styled(Popup)`
  font-size: ${fontSize};
  padding: ${cardPadding};
`;

StyledPopup.propTypes = {
  field: PropTypes.string.isRequired,
  properties: PropTypes.object.isRequired,
}

export default StyledPopup;
