import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  initializeMap,
  addOrUpdateChloroplethSource,
  addChloroplethLayer,
  updateChloroplethLayer,
} from './utils';
import usePopup from './Popup/usePopup';
import theme from '../../theme';
const {
  borderRadius,
  breakPointLg,
  cardBackground,
  cardBorderColor,
  cardBorderWidth,
  cardPadding,
} = theme;

const MapContainer = styled.div`
  height: 60vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (min-width: ${breakPointLg}) {
    height: 100%;
  }

  .mapboxgl-canvas {
    outline: none;
  }

  .mapboxgl-ctrl-bottom-right,
  .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .data-snapshot-popup {
    .mapboxgl-popup-content {
      background-color: ${cardBackground};
      border: ${cardBorderWidth} solid ${cardBorderColor};
      border-radius: ${borderRadius};
      padding: ${cardPadding};
    }
    &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
      border-top-color: ${cardBackground};
      margin-top: -${cardBorderWidth};
    }
    &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
      border-bottom-color: ${cardBackground};
      margin-bottom: -${cardBorderWidth};
    }
    &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
      border-left-color: ${cardBackground};
      margin-left: -${cardBorderWidth};
    }
    &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
      border-right-color: ${cardBackground};
      margin-right: -${cardBorderWidth};
    }
    &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
      margin-left: ${cardBorderWidth};
    }
    &.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
      margin-right: ${cardBorderWidth};
    }
  }
`;

const initialMapViewport = {
  lat: 41.5801,
  lng: -71.4774,
  zoom: 1.2,
};

function Map({
  dataValues,
  geoJSON,
  field,
  setSelected,
}) {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [viewport, setViewport] = useState(initialMapViewport);
  console.log(viewport)

  // set up map
  useEffect(() => {
    if (!map) {
      initializeMap({ setMap, mapContainer, initialMapViewport, setViewport });
    } else {
      addOrUpdateChloroplethSource({ map, geoJSON });
      addChloroplethLayer({ map });
      map.on('click', 'chloropleth-fills', (e) => setSelected(e.features[0].properties.location));
    }
  }, [map, geoJSON, setSelected]);

  // update chloropleth layer colors
  useEffect(() => {
    updateChloroplethLayer({ map, dataValues, field });
  }, [map, dataValues, field]);

  usePopup({ map, field });

  return (
    <MapContainer ref={mapContainer} />
  );
}

Map.propTypes = {
  dataValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  geoJSON: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default Map;
