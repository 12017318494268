import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.workerClass = MapboxWorker;

const { REACT_APP_MAPBOX_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

export default function initializeMap({ setMap, mapContainer, initialMapViewport, setViewport }) {
  const { lat, lng, zoom } = initialMapViewport;

  const map = new mapboxgl.Map({
    container: mapContainer.current,
    style: "mapbox://styles/digitalrabbit/ckir2wd1n05fg18ovaysru5ze",
    center: [lng, lat],
    zoom: zoom,
  });

  map.on('move', () => {
    setViewport({
      lat: map.getCenter().lat.toFixed(4),
      lng: map.getCenter().lng.toFixed(4),
      zoom: map.getZoom().toFixed(2),
    });
  });

  map.on("load", () => {
    setMap(map);
    map.resize();
  });
};
