import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'digital-rabbit-cl';

import { formatValue, isPercentageField } from '../utils';
import theme from '../theme';
const {
  backgroundColor,
  breakPointLg,
  fontColor,
  fontSize,
} = theme;

const RankingsList = ({
  className,
  constituents,
  computedField,
  scrollToSelection,
  field,
  setSelected,
  selectedLocation,
}) => {
  const selectedItem = useCallback(node => {
    if (
      window.matchMedia(`(min-width: ${breakPointLg})`).matches
      && node !== null
      && !scrollToSelection
    ) {
      node.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
  }, [scrollToSelection]);

  return (
    <div className={className} >
      <ul
        style={{
          listStyleType: 'none',
          margin: '0',
          padding: '0',
        }}
      >
        {
          constituents
            .filter(entry => entry[computedField] && entry[computedField] !== 0)
            .sort((a, b) => b[computedField] - a[computedField])
            .map((entry, i) => {
              const match = selectedLocation === entry.location;

              return (
                <li key={entry.location} ref={(!selectedLocation && i === 0) || match ? selectedItem : null} >
                  <Button
                    onClick={() => setSelected(entry.location, true)}
                    style={{
                      border: 'none',
                      backgroundColor: match ? fontColor : 'transparent',
                      color: match ? backgroundColor : fontColor,
                      display: 'flex',
                      fontSize: 'inherit',
                      justifyContent: 'space-between',
                      padding: '.1em .2em',
                      width: '100%',
                    }}
                    color={fontColor}
                    hoverTextColor={backgroundColor}
                    outline
                  >
                    <div style={{ fontFeatureSettings: 'tnum' }}>{i + 1}.&nbsp;</div>
                    <div style={{ flex: '1 1 auto', textAlign: 'left' }}>{entry.location}</div>
                    <div style={{ fontFeatureSettings: 'tnum', paddingLeft: '.25em' }}>{formatValue(entry[computedField], isPercentageField(field))}</div>
                  </Button>
                </li>
              );
            })
        }
      </ul>
    </div>
  );
};

RankingsList.defaultProps = {
  selectedLocation: '',
};

RankingsList.propTypes = {
  constituents: PropTypes.PropTypes.array.isRequired,
  computedField: PropTypes.string.isRequired,
  scrollToSelection: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  selectedLocation: PropTypes.string,
};

const StyledRankingsList = styled(RankingsList)`
  font-size: .8em;
  overflow: scroll;
  margin-right: -1rem;
  min-height: calc(${fontSize} * 7);
  padding-right: 1rem;

  @media screen and (min-width: ${breakPointLg}) {
    height: auto;
  }
`;

export default StyledRankingsList;