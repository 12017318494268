const dataPointHFLegendOWID = {
  // active: 'Active Cases',
  total_cases: 'Total Cases',
  // critical: 'Critical Cases',
  total_deaths: 'Total Deaths',
  // recovered: 'Total Recovered',
  new_tests: 'New Tests',
  total_tests: 'Tests',
  new_cases: 'New Cases',
  new_deaths: 'New Deaths',
  // positive_rate: 'Positive Tests (%)',
  // tests_per_case: 'Tests Per Case',
  total_vaccinations: 'Total Vaccinations',
  // total_vaccinations_per_hundred: 'Vaccination (%)',
};

export default dataPointHFLegendOWID;
