import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';
const {
  borderRadius,
  cardBorderColor,
  cardBorderWidth,
  cardBackground,
  cardMargin,
  cardPadding,
  fontSize,
} = theme;

const Card = ({
  children,
  style = {},
  className="",
  title = '',
}) => (
  <div
    className={className}
    style={{ style }}
  >
    { title && <div style={{ lineHeight: '1', marginBottom: cardPadding, opacity: '0.5' }}>{title}</div> }
    <div
      style={{
        padding: `0 ${cardPadding}`,
        minHeight: '0',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {children}
    </div>
  </div>
);

const StyledCard = styled(Card)`
  background-color: ${cardBackground};
  border: ${cardBorderWidth} solid ${cardBorderColor};
  border-radius: ${borderRadius};
  display: flex;
  flexDirection: column;
  fontSize: ${fontSize};
  margin: ${cardMargin};
  padding: ${cardPadding};
`;

StyledCard.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default StyledCard;
