const theme = {
  backgroundColor: 'rgb(15, 15, 25)',
  borderColor: 'white',
  borderRadius: '2px',
  borderWidth: '3px',
  breakPointLg: '1024px',
  breakPointMd: '768px',
  breakPointSm: '475px',
  cardBorderColor: 'rgba(255, 255, 255, .1)',
  cardBorderWidth: '2px',
  cardBackground: 'rgba(15, 15, 25, .85)',
  cardMargin: '9px',
  cardPadding: '9px',
  colorPrimary: '#081317',
  colorSecondary: 'rgba(255, 255, 255, .9)',
  fontSize: '22px',
  fontColor: 'white',
  fontWeight: 700,
  headerInnerHeight: '1.5em',
  headerInnerHeightMd: '3em',
  headerPadding: '.5em',
  headerPaddingMd: '1em',
};

export default theme;
