// determine whether to show decimals
export default function formatValue(value, perc) {
  let displayValue;
  if (value === undefined) displayValue = '---';
  else if (
    value >= 10
    || (Number.isInteger(+(Math.round(value * 10) / 10).toFixed(1)) && +(Math.round(value * 10) / 10).toFixed(1) > 0)
  ) displayValue = Math.round(value).toLocaleString();
  else if (value >= 1) displayValue = (Math.round(value * 10) / 10).toFixed(1).toLocaleString();
  else if (value === 0) displayValue = '0';
  else if (value < .001) displayValue = '<.001';
  else displayValue = value.toFixed(3).toLocaleString();

  // add percent sign
  if (perc && displayValue !== '---') displayValue += '%';

  return displayValue;
}
