import { dataPointHFLegendOWID } from './';

export default function getDataFields(covidData) {
  const fieldsFromData = Object
    .keys(covidData)
    .filter(field => Object.keys(dataPointHFLegendOWID).includes(field));

  return [
    ...fieldsFromData,
    'positive_rate',
    'tests_per_case',
  ];
}