import React from 'react';
import styled from 'styled-components';

import theme from '../theme';
const {
  breakPointMd,
  breakPointLg,
  cardBackground,
  colorSecondary,
  fontSize,
  fontWeight,
  headerPadding,
  headerPaddingMd,
} = theme;

const Header = ({ children, className }) => (
  <header className={className}>
    {children}
  </header>
);

const StyledHeader = styled(Header)`
  background-color: ${cardBackground};
  color: ${colorSecondary};
  display: flex;
  align-items: center;
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  padding: ${headerPadding};
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  text-align: left;
  z-index: 1000;

  @media screen and (min-width: ${breakPointMd}) {
    padding: ${headerPaddingMd};
  }

  @media screen and (min-width: ${breakPointLg}) {
    background-color: transparent;
    background-image: linear-gradient(${cardBackground}, transparent);
    padding: ${headerPaddingMd};
  }
`;

export default StyledHeader;
