import styled from 'styled-components';

import Card from './Card';
import theme from '../theme';
const { backgroundColor, breakPointLg, cardBackground } = theme;

const StyledControlPanelCard = styled(Card)`
  background-color: ${backgroundColor};
  height: calc(100% - 33px);
  margin: 0;

  @media screen and (min-width: ${breakPointLg}) {
    background-color: ${cardBackground};
    margin: 0 .5em .5em;
  }
`;

export default StyledControlPanelCard;
