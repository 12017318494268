export default function addOrUpdateChloroplethSource({ map, geoJSON }) {
  if (!map.getSource('chloropleth-features')) {
    map.addSource('chloropleth-features', {
      'type': 'geojson',
      'data': geoJSON,
    });
  } else {
    map.getSource('chloropleth-features').setData(geoJSON);
  }
}
