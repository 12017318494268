import React from 'react';
import styled from 'styled-components';

import theme from '../theme';
const {
  breakPointMd,
  breakPointLg,
  fontSize,
  headerInnerHeightMd,
  headerPaddingMd,
} = theme;

const Sidebar = ({ children, className="" }) => <div className={className}>{children}</div>;

const StyledSidebar = styled(Sidebar)`
  font-size: ${fontSize};
  overflow: scroll;
  position: absolute;
  width: 100%;
  margin: 60vh 0 0;

  @media screen and (min-width: ${breakPointMd}) {
    font-size: calc(${fontSize} * 1.6);
  }

  @media screen and (min-width: ${breakPointLg}) {
    border-top: none;
    font-size: ${fontSize};
    order: 1;
    position: absolute;
    top: calc(${headerInnerHeightMd} + (2 * ${headerPaddingMd}));
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 14em;
  }
`

export default StyledSidebar;
