import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import Popup from './';
import theme from '../../../theme';
const { breakPointLg } = theme;

mapboxgl.workerClass = MapboxWorker;

function throttle(callback, limit) {
  var waiting = false;
  return function() {
    if (!waiting) {
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(function() {
        waiting = false;
      }, limit);
    }
  }
}

function initializePopup({ setPopup, map }) {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    className: 'data-snapshot-popup',
    maxWidth: '100%',
    offset: 5,
  });

  popup
    .trackPointer()
    .addTo(map);

  setPopup(popup);
}

function assignHoverListeners({ map, popup }) {
  function showPopup(e) {
    setPopupContent(e);
    map.getCanvas().style.cursor = 'pointer';
    const popupElem = popup.getElement();
    if (popupElem) {
      popupElem.style.display = 'flex';
    }
  }
  
  function removePopup() {
    map.getCanvas().style.cursor = 'grab';
    const popupElem = popup.getElement();
    if (popupElem) {
      popupElem.style.display = 'none';
    }
  }
  
  function setPopupContent(e) {
    const popupDiv = document.createElement('div');
    ReactDOM.render(<Popup properties={e.features[0].properties} field={popup.selectedField} />, popupDiv);
    popup.setDOMContent(popupDiv);
  }

  map.on('mouseenter', 'chloropleth-fills', showPopup);
  map.on('mouseleave', 'chloropleth-fills', removePopup);
  map.on('mousemove', 'chloropleth-fills', throttle(setPopupContent, 25));
}

const usePopup = ({ map = null, field }) => {
  const [popup, setPopup] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!map || !window.matchMedia(`(min-width: ${breakPointLg})`).matches) return;
    if (!popup) initializePopup({ setPopup, map });
    else if (!loaded) {
      assignHoverListeners({ map, popup });
      setLoaded(true);
    }
  }, [map, popup, loaded]);

  useEffect(() => {
    if (popup && popup.selectedField !== field) popup.selectedField = field;
  }, [popup, field]);
};

usePopup.propTypes = {
  map: PropTypes.object,
  field: PropTypes.string.isRequired,
};

export default usePopup;
