export default function addChloroplethLayer({ map }) {
  if (!map.getLayer('chloropleth-fills')) {
    var layers = map.getStyle().layers;
    // Find the index of the first label layer in the map style
    var firstLayerId;
    for (var i = 0; i < layers.length; i++) {
      if (layers[i]['source-layer'] === 'place_label') {
        firstLayerId = layers[i].id;
        break;
      }
    }

    map.addLayer({
      'id': 'chloropleth-fills',
      'type': 'fill',
      'source': 'chloropleth-features',
      'layout': {},
      'paint': {
        'fill-opacity': 1,
      },
    }, firstLayerId);
    
    map.addLayer({
      'id': 'chloropleth-borders',
      'type': 'line',
      'source': 'chloropleth-features',
      'layout': {},
      'paint': {
        'line-color': 'rgba(255, 0, 0, .5)',
        'line-width': 1,
      },
    }, firstLayerId);
  }
}
