import { useState, useEffect } from 'react';

import {
  getDataFields,
  buildGeoJSONWithData,
  countriesJSON,
} from './';

function fetchOWIDData() {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = fetch('https://digital-rabbit-mailer.herokuapp.com/api/v1/covid/world', { signal })
    .then((res) => {
      if (res.ok) return res.json();
      throw new Error('Error fetching owid data.');
    });
    
  promise.cancel = () => controller.abort();

  return promise;
}

function formatCovidData(owidData) {
  const geoJSONWithData = buildGeoJSONWithData(owidData.constituents, countriesJSON);

  return {
    fields: getDataFields(owidData),
    owidData: {
      ...owidData,
      constituents: Object.values(geoJSONWithData.features).map(({ properties }) => ({ ...properties })),
    },
    geoJSONWithData,
  };
}

export default function useCovidData() {
  const [status, setStatus] = useState('fetching');
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    covidCountryData: null,
    covidTotals: null,
    fields: null,
    owidData: null,
    geoJSONWithData: null,
  });

  useEffect(() => {
    const owidDataFetch = fetchOWIDData();

    owidDataFetch
      .then(formatCovidData)
      .then((data) => {
        setData(data);
        setStatus('success');
      })
      .catch((err) => {
        setStatus('error');
        setError(err);
      });

    return () => owidDataFetch.cancel();
  }, []);

  const {
    fields,
    owidData,
    geoJSONWithData,
  } = data;

  return {
    status,
    error,
    fields,
    owidData,
    geoJSONWithData,
  };
};
