import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import theme from '../theme';
const {
  backgroundColor,
  breakPointMd,
  fontColor,
  fontSize,
  fontWeight,
  headerInnerHeight,
  headerInnerHeightMd,
} = theme;

const BrandImageDiv = styled.div`
  background-image: url(/logo-light.svg);
  background-repeat: no-repeat;
  height: ${headerInnerHeight};
  width: 4em;

  @media screen and (min-width: ${breakPointMd}) {
    background-image: url(/logo-light.svg);
    height: ${headerInnerHeightMd};
    width: 8em;
  }
`;

const HeaderTitle = styled.div`
  font-size: .7em;
  margin-left: 1em;
  pointer-events: auto;

  @media screen and (min-width: ${breakPointMd}) {
    font-size: 1.3em;
    margin-left: 1.5em;
  }
`;

const Layout = ({ children, className="" }) => (
  <div className={className}>
    <Header>
      <a
        href="https://www.digitalrabb.it/"
        rel="noopener noreferrer"
        style={{ pointerEvents: 'auto' }}
        target="_blank"
      >
        <BrandImageDiv />
      </a>
      <HeaderTitle>COVID-19 Tracker</HeaderTitle>
    </Header>
    {children}
  </div>
);

const StyledLayout = styled(Layout)`
  background-color: ${backgroundColor};
  color: ${fontColor};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  position: relative;
  width: 100vw;
`;

export default StyledLayout;