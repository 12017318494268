import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Select } from 'digital-rabbit-cl';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import qs from 'query-string';

import Layout from './Layout';
import Map from './components/Map';
import Sidebar from './Layout/Sidebar';
import ControlPanelCard from './components/ControlPanelCard';
import RankingsList from './components/RankingsList';
import {
  useCovidData,
  formatValue,
  dataPointHFLegendOWID,
  isPercentageField,
  selectionToURL,
  urlToSelection,
} from './utils';
import theme from './theme';
const {
  backgroundColor,
  borderRadius,
  breakPointLg,
  cardMargin,
  fontColor,
} = theme;

function App() {
  const {
    status,
    error,
    fields,
    owidData,
    geoJSONWithData,
  } = useCovidData();

  const history = useHistory();
  const location = useLocation();

  // determine selected query parameters, if there are any
  const queryString = qs.parse(location.search);
  const { field = 'total_cases' } = queryString;
  if (!dataPointHFLegendOWID[field]) {
    const { field, ...newQS } = queryString;
    const searchString = qs.stringify(newQS);
    history.replace({ search: searchString });
  }
  const perThousand = queryString.perThousand === 'true' && !isPercentageField(field) && field !== 'tests_per_case';

  // set query parameters
  const setParameters = ({ target: { name, value, type, checked } }) => {
    let newValue = value;
    if (type === 'checkbox' || type === 'radio') newValue = checked;
    const searchString = qs.stringify({ ...queryString, [name]: newValue });
    history.replace({ search: searchString });
  };

  // determine selected country, if there is one
  let country;
  const countryParam = useParams().country;
  if (
    countryParam
    && status === 'success'
  ) {
    // if the country parameter exists in data, set it, otherwise redirect to home
    if (owidData.constituents.find(({ location }) => location === urlToSelection(countryParam))) {
      country = urlToSelection(countryParam);
    } else {
      history.replace({
        pathname: '/',
        search: location.search,
      });
    }
  }

  // set selected country as URL parameter
  const [scrollToSelection, setScrollToSelection] = useState(false);
  const setCountry = useCallback((selectedCountry, noScroll) => {
    const { location } = history;
    let pathname = '/';

    setScrollToSelection(noScroll || false);

    if (selectedCountry) {
      pathname = `/${selectionToURL(selectedCountry)}`;
    }
    history.replace({
      pathname,
      search: location.search,
    });
  }, [history]);

  const computedField = perThousand ? field + '_per_thousand' : field;

  // determine total value based on selection
  let totalValue;
  if (status === 'success') {
    totalValue = country
      ? owidData.constituents.find(({ location }) => location === country)[computedField]
      : owidData[computedField];
  }

  return (
    <Layout>
      {
        status === 'error' ? (
          <div style={{ textAlign: 'left' }}>{(error && error.message) ? error.message : 'Something went wrong.'}</div>
        ) : status === 'fetching' ? ( 
          <div style={{ textAlign: 'left' }}>Loading...</div>
        ) : (
          <>
            <Map
              dataValues={owidData.constituents.map(({ [computedField]: dataField }) => dataField || 0)}
              geoJSON={geoJSONWithData}
              field={computedField}
              setSelected={setCountry}
            />
            <Sidebar>
              <ControlPanelCard>
                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', width: '100%' }}>
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '3px',
                      display: window.matchMedia(`(min-width: ${breakPointLg})`).matches ? 'none' : 'initial',
                      height: '.15em',
                      margin: '0 auto .3em',
                      width: '4em',
                    }}
                  />
                  <div style={{ marginBottom: cardMargin, marginTop: cardMargin }}>
                    <Select
                      name="field"
                      color={fontColor}
                      fontSize='.8em'
                      onChange={setParameters}
                      style={{ borderRadius: borderRadius }}
                      value={field}
                    >
                      {
                        fields.map((f) => (
                          dataPointHFLegendOWID[f]
                            ? <option key={f} value={f}>{dataPointHFLegendOWID[f]}</option>
                            : null
                        ))
                      }
                    </Select>
                  </div>
                  <div style={{ marginBottom: cardMargin }}>
                    <Checkbox
                      name="perThousand"
                      checked={perThousand}
                      color={fontColor}
                      disabled={isPercentageField(field) || field === 'tests_per_case'}
                      fontSize='.9em'
                      label="Per 1,000"
                      onChange={setParameters}
                      style={{ marginLeft: '.5em', borderRadius: borderRadius }}
                    />
                  </div>
                  <div style={{ color: 'red', fontSize: '1.65em', textAlign: 'center' }}>
                    { formatValue(totalValue, isPercentageField(field))}
                  </div>
                  {
                    country && (
                      <Button
                        color={fontColor}
                        textColor={backgroundColor}
                        onClick={() => setCountry(null)}
                        fontSize='.8em'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: cardMargin,
                        }}
                      >
                        <span>{country}</span>
                        <span
                          style={{ 
                            fontSize: '1.7em',
                            lineHeight: '0.8',
                            marginLeft: '0.25em',
                          }}
                        >
                          &times;
                        </span>
                      </Button>
                    )
                  }
                  <div>
                    <hr />
                  </div>
                  <RankingsList
                    constituents={owidData.constituents}
                    computedField={computedField}
                    scrollToSelection={scrollToSelection}
                    field={field}
                    setSelected={setCountry}
                    selectedLocation={country}
                  />
                </div>
              </ControlPanelCard>
            </Sidebar>
          </>
        )
      }
    </Layout>
  );
}

export default App;
