export default function buildGeoJSONWithData(covidData, geoJSON) {
  if (!covidData) return {};

  const geoJSONWithData = {
    ...geoJSON,
    features: geoJSON.features.map((feature) => {
      const { admin, population } = feature.properties; 
      const properties = {
        ...covidData[feature.properties.iso_a3],
        location: covidData[feature.properties.iso_a3]?.location ?? admin,
        population,
      };

      return {
        ...feature,
        properties,
      }
    })
  };

  return geoJSONWithData;
}
