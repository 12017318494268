import { nTile } from 'stat-functions';

function buildQuantileRGBAGradientStops({ dataValues, quantiles, fromRGBA, toRGBA }) {
  const colors = [];
  const quantArray = [0, ...nTile(dataValues, quantiles)];
  quantArray.forEach((quant, i) => {
      const color = toRGBA.map((val, colorValIndex) => {
        const colorValDiff = val - fromRGBA[colorValIndex];
        return (
          fromRGBA[colorValIndex] + ((colorValDiff / (quantArray.length - 1)) * i)
        );
      });

      colors.push([quant, `rgba(${color.join(',')})`]);
    });
  
  return colors;
}

export default function updateChloroplethLayer({ map, dataValues, field }) {
  if (!map) return;
  if (map.getLayer('chloropleth-fills')) {
    const quantiles = 10;
    const fromRGBA=[0, 0, 25, .75];
    const toRGBA=[180, 0, 0, .75];

    const colorStops = [];
    buildQuantileRGBAGradientStops({ dataValues, quantiles, fromRGBA, toRGBA })
      .forEach(([quant, color], i) => {
        if (i === 0) {
          colorStops.push(color);
          return;
        }
        if (quant === 0) return; // all zeros get the "from" color
        colorStops.push(quant, color);
      });

      const fillColor = colorStops.length < 2
        ? ['interpolate', ['linear'], ['get', field], 0, `rgba(${fromRGBA.join(',')})`, Math.max(...dataValues) || 1, `rgba(${toRGBA.join(',')})`]
        : ['step', ['get', field], ...colorStops];

    map.setPaintProperty(
      'chloropleth-fills',
      'fill-color',
      fillColor
    );
  }
}
